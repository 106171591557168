

<div [style]="{ height: splitPanelHeight + 'px' }">
  <p-splitter [style]="{ height: '100%' }" styleClass="mb-8" layout="vertical" (onResizeEnd)="onSplitterResizeEnd($event)" >

    <ng-template pTemplate>
      <ag-grid-angular class="ag-theme-quartz" [rowData]="tmRows" [style]="{ height: '100%', width: '100%' }"
        [columnDefs]="colDefs" [pagination]="true" [paginationPageSize]="10" [paginationPageSizeSelector]="[10,25,50]"
        sideBar="filters" (gridReady)="onGridReady($event)" (selectionChanged)="onSelectionChanged($event)"
        [rowSelection]="{ mode: 'singleRow', checkboxes: false, enableClickSelection: true}" [cellSelection]="true" />
    </ng-template>

    <ng-template pTemplate>

      <div style="height: 100%; width: 100%;">
        <p-tabView [style]="{ width: '100%', height: '100%' }">

          <p-tabPanel header="LCMS" [style]="{ width: '100%', height: '100%' }">

            <div class="grid mt-1" style="height: 100%;">
              <div class="col-6 text-center show-border container" [style]="{ height: graphBorderHeight + 'px' }">
                <div class="top-left">Total Ion Chromatogram</div>
                @if(selectedRow?.totalIonChromatogramImg) {
                  <p-image [src]=selectedRow.totalIonChromatogramImg alt="Total Ion Chromatogram" [preview]="true"
                  [imageStyle]="{ height: graphHeight + 'px', width: '100%', objectFit: 'contain' }" />
                }
              </div>
              <div class="col-6 text-center show-border container" [style]="{ height: graphBorderHeight + 'px' }">
                <div class="top-left">MS1</div>
                @if(selectedRow?.mS1SpectraImg) {
                  <p-image [src]=selectedRow.mS1SpectraImg alt="MS1" [preview]="true"
                  [imageStyle]="{ height: graphHeight + 'px', width: '100%', objectFit: 'contain' }" />
                }
              </div>
              <div class="col-6 text-center show-border container" [style]="{ height: graphBorderHeight + 'px' }">
                <div class="top-left">Extracted Ion Chromatogram</div>
                @if(selectedRow?.extractedIonChromatogramImg) {
                  <p-image [src]=selectedRow.extractedIonChromatogramImg alt="Extracted Ion Chromatogram" [preview]="true"
                  [imageStyle]="{ height: graphHeight + 'px', width: '100%', objectFit: 'contain' }" />
                }
              </div>
              <div class="col-6 text-center show-border container" [style]="{ height: graphBorderHeight + 'px' }">
                <div class="top-left">MS2</div>
                @if(selectedRow?.mS2SpectraImg) {
                  <p-image [src]=selectedRow.mS2SpectraImg alt="MS2" [preview]="true"
                  [imageStyle]="{ height: graphHeight + 'px', width: '100%', objectFit: 'contain' }" />
                }
              </div>
            </div>

          </p-tabPanel>

          <p-tabPanel header="Hypothesis">
            @if(selectedRow?.hypothesis?.length > 0) {
              <textarea readonly [style]="{ height: bottomPanelHeight + 'px', width: '100%', resize: 'none' }"
                [value] = "selectedRow?.hypothesis">
              </textarea>
            }
          </p-tabPanel>

          <p-tabPanel header="Isolated Materials">
            @if(selectedRow?.targetMaterials?.length > 0) {
              <ag-grid-angular class="ag-theme-quartz" [rowData]="selectedRow?.targetMaterials"
                [style]="{ height: bottomPanelHeight + 'px', width: '100%' }"
                [columnDefs]="tmColDefs" [pagination]="true" [paginationPageSize]="10" [paginationPageSizeSelector]="[10,25,50]"
                sideBar="filters" [rowSelection]="{ mode: 'singleRow', checkboxes: false}" [cellSelection]="true" />
            }
          </p-tabPanel>

        </p-tabView>

      </div>

    </ng-template>
  </p-splitter>
</div>


