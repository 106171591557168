import { HostListener, Injectable, OnInit, signal } from '@angular/core';
import { Batch, ModuleCount, TargetSelectionApi } from './target-selection-api/target-selection-api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, Observable, shareReplay, Subject } from 'rxjs';
import { NavigationEnd, NavigationExtras, NavigationStart, Params, Router } from '@angular/router';
import { LoaderService } from './loader.service';
import { LocalStorageService } from './local-storage.service';
import { MSGraphService } from './msgraph.service';
import { BrowserNavButtonsService } from './browser-nav-buttons.service';


export class GlobalFilter {
  cscore: number;

}

export class TSModule {
  name: string;
  route: string;
  count?: ModuleCount[];
  filterRoute?: string;
  showTargetButtons?: boolean = false;
  showInAppMenu?: boolean = true;
  description?: string;

  /**
   *
   */
  constructor() {
    // this.showTargetButtons = false;
    // this.showInAppMenu = true;

  }

}





@Injectable({
  providedIn: 'root'
})
export class AppService {

  //batches
  batches$: Observable<Batch[]>;
  selectedBatchName = signal("");
  batchSelected$ = new Subject<string>();
  targetButtonClicked$ = new Subject<string>();
  refreshDatasetClicked$ = new Subject<boolean>();

  showLockButton = signal<boolean>(false);
  showUnLockButton = signal<boolean>(false);
  showRejectButton = signal<boolean>(false);
  showTargetButton = signal<boolean>(false);

  showRefreshButton() {
    let selModule = this.selectedModule();
    let showRefresh: boolean = selModule && selModule.name != "Library";
    return showRefresh;
  }

  //filter
  globalFilter = new GlobalFilter();
  filterApplied$ = new Subject<void>();
  selectedModule = signal<TSModule>(null);
  currentUser: string;
  windows: WindowProxy[] = [];
  paramsMap: Map<string, any> = new Map<string, any>();

  //modules
  modules: TSModule[] = [
    { name: "Strains", route: "strains", description: "Collection of all the strains characterized through metabologenomics, providing strain IDs and connectivity to Bionexus." },
    { name: "Metabolites", route: "metabolites", showTargetButtons: true, description: "Collection of all the metabolites (as mass spectrometry features called MS nodes) discovered in the strains evaluated. Similar metabolites are grouped into spectral families." },
    { name: "Metabolites Deep Dive", route: "metabolites-deep-dive", showTargetButtons: true, showInAppMenu: false },
    { name: "Biosynthetic Gene Clusters", route: "bgc", showTargetButtons: true, description: "List of biosynthetic gene clusters and gene cluster families across all the strains." },
    { name: "Targets", route: "targets", description: "Metabolite or Gene Cluster based natural product targets selected for isolation and characterization." },
    { name: "Library", route: "library", description: "List of all small molecule natural products in databases and annotated biosynthetic gene clusters." }
  ];


  constructor(
    private client: TargetSelectionApi,
    private router: Router,
    private loaderService: LoaderService,
    private localStorage: LocalStorageService,
    private graphService: MSGraphService,
    private navBtsService: BrowserNavButtonsService) {

    this.batches$ = this.client.getAllBatches().pipe(shareReplay(1));

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // We've finished navigating
        console.log(event);

        var module = this.modules.find(x => "/" + x.route == event.url.replace(/\?.*$/, ''));
        if (module) {
          this.selectedModule.set(module);
          this.localStorage.setItem<TSModule>("module", module);
        }
        else {
          this.selectedModule.set(undefined);
        }

      }
      else if (event instanceof NavigationStart) {
        this.navBtsService.navButtonClicked = event.navigationTrigger == "popstate";
      }
    });

    graphService.getMyProile$().pipe(takeUntilDestroyed()).subscribe(x => {
      this.currentUser = x.userPrincipalName;
    });

  }

  menuModules(): TSModule[] {
    return this.modules.filter(x => x.showInAppMenu != false);
  }

  beforeUnload() {
    for (let w of this.windows) {
      try {
        w.close();
      }
      catch { }
    }
  }

  restoreLocalStorage() {
    var batchName = this.localStorage.getItem<string>("batchName");
    //var module = this.localStorage.getItem<TSModule>("module");

    // if (module) {
    //   this.selectModule(module);
    // }

    if (batchName) {
      this.selectBatch(batchName);
    }
  }

  selectBatch(batchName: string) {
    this.selectedBatchName.set(batchName);
    this.batchSelected$.next(batchName);
    this.localStorage.setItem<string>("batchName", batchName);


    this.loaderService.setLoading(true);

    this.client.getModuleCounts(batchName, undefined).subscribe(x => {
      this.modules.find(x => x.name == "Strains").count = x.strains;
      this.modules.find(x => x.name == "Metabolites").count = x.metabolites;
      this.modules.find(x => x.name == "Biosynthetic Gene Clusters").count = x.bgc;
      this.modules.find(x => x.name == "Targets").count = x.targets;
      this.modules.find(x => x.name == "Library").count = x.library;
    }, null,
      () => {
        this.loaderService.setLoading(false);
      }
    );

  }

  targetButtonClicked(state: string) {
    this.targetButtonClicked$.next(state);
  }

  applyFilter() {
    this.filterApplied$.next();
  }

  selectModule(module: TSModule, navigate = true) {
    if (navigate) {
      this.navigate(module.route, module.filterRoute);
    }
    else {
      this.selectedModule.set(module);
    }
    //this.localStorage.setItem<TSModule>("module", module);
  }

  navigate(route: string, filterRoute: string | undefined) {
    this.router.navigate(['',
      {
        outlets: {
          filter: null
        }
      }]).then(v => {
        this.router.navigate([route]).then(v => {

        });

      });
  }

  showFilter() {
    if (this.selectedModule()?.filterRoute) {
      this.router.navigate(['',
        {
          outlets: {
            filter: this.selectedModule().filterRoute
          }
        }]);
    }
  }

  hideFilter() {
    this.router.navigate(['',
      {
        outlets: {
          filter: null
        }
      }]);
  }

  refresh() {
    this.refreshDatasetClicked$.next(true);
  }

  private openWindow(url: string, windowParams: any = null) {
    let w = window.open(url, "_blank");
    this.windows.push(w);

    if (windowParams) {

      let wParams = w as { [key: string]: any };

      let pKeys = Object.keys(windowParams);
      for (const pKey of pKeys) {
        wParams[pKey] = windowParams[pKey];
      }

    }

  }

  openModuleNewWindow(value: string[] | string, queryParam: string, moduleName: string) {
    const queryParams: any = {};
    queryParams[queryParam] = value;
    this.openModuleNewWindowMultipleParams(queryParams, moduleName);
  }

  openModuleNewWindowMultipleParams(queryParams: any, moduleName: string) {
    var url = this.router.serializeUrl(this.router.createUrlTree([`/${moduleName}`]));
    this.openWindow(url, queryParams);
  }

  navigateToModuleWithParams(params: any, moduleName: string) {
    let keyGuid = this.saveParameters(params);
    let queryParams: any = {};
    queryParams['paramsGUID'] = keyGuid;
    let navigationExtras: NavigationExtras = {
      queryParams
    };
    this.router.navigate([`/${moduleName}`], navigationExtras);
  }

  saveParameters(params: any): string {
    if (!params) {
      return null;
    }

    let newKeyGuid = this.getGUID();
    this.paramsMap.set(newKeyGuid, params);

    return newKeyGuid;
  }

  getParameters(paramsKeyGuid: string): any {
    if (!paramsKeyGuid) {
      return null;
    }

    let params = null;

    if (this.paramsMap.has(paramsKeyGuid)) {
      params = this.paramsMap.get(paramsKeyGuid);
      this.paramsMap.delete(paramsKeyGuid);
    }

    return params;
  }

  getGUID(): string {
    let d = new Date().getTime();
    const guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return guid;
  }

  retrieveParameter(queryParams: Params, paramName: string): any {
    let wParams = window as { [key: string]: any };

    if (wParams[paramName]) {
      return wParams[paramName];
    }
    else if (queryParams[paramName]) {
      return JSON.parse(queryParams[paramName]);
    }

    return undefined;
  }

}




// this.loaderService.setLoading(true);

//       this.client.getModuleCounts(x, 21000).subscribe(x => {
//         this.modules.find(x => x.name == "Strains").count = x.strains;
//         this.modules.find(x => x.name == "Metabolites").count = x.metabolites;
//         this.modules.find(x => x.name == "Biosynthetic Gene Clusters").count = x.bgc;
//         this.modules.find(x => x.name == "Targets").count = x.targets;
//         this.modules.find(x => x.name == "Library").count = x.library;


//       }, null,
//         () => {
//           this.loaderService.setLoading(false);
//         }
//       );
