import { Component, input, Input, OnInit, signal } from '@angular/core';
import { ChipModule } from 'primeng/chip';
import { MSGraphService, ProfileType } from '../services/msgraph.service';
import { AvatarModule } from 'primeng/avatar';
import { TooltipModule } from 'primeng/tooltip';
import { CommonModule } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { forEach } from 'lodash';


const blob2base64 = (blob: Blob, mimeType: string) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const dataUrlPrefix = `data:${mimeType};base64,`;
      const base64WithDataUrlPrefix = <string>reader.result;
      const base64 = dataUrlPrefix + base64WithDataUrlPrefix;
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

type ViewMode = "chip" | "avatar";



@Component({
  selector: 'app-user-chip',
  standalone: true,
  imports: [
    ChipModule,
    AvatarModule,
    TooltipModule,
    CommonModule,
    OverlayPanelModule
  ],
  templateUrl: './user-chip.component.html',
  styleUrl: './user-chip.component.scss'
})
export class UserChipComponent implements OnInit {
  @Input() email: string;
  @Input() mode: ViewMode = "chip";

  userImage: string;
  //userName: string;
  profile: ProfileType;
  noPhoto = false;
  /**
   *
   */
  constructor(private graphService: MSGraphService) {
  }

  async ngOnInit() {
    this.graphService.getUserPhoto$(this.email).subscribe(async jpg => {
      this.userImage = <string>await blob2base64(jpg, "image/jpeg");
    }, err => {
      this.userImage = undefined;
      this.noPhoto = true;
    });

    this.graphService.getUserProile$(this.email).subscribe(p => {
      //this.userName = p.displayName;
      this.profile = p;
    });
  }

  getLabel(): string {
    let parts = this.email.replace(new RegExp(".com", 'gi'), "").split(".");
    let label = "";

    for (let i = 0; i < parts.length && i < 2; i++) {
      label += parts[i].charAt(0).toUpperCase();
    }

    return label;
  }
}
