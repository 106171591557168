import { PanelModule } from 'primeng/panel';
import { Component, ViewChild } from '@angular/core';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputNumberModule } from 'primeng/inputnumber';
import { ButtonModule } from 'primeng/button';
import { FloatLabelModule } from 'primeng/floatlabel';
import { MoleculeStructureComponent } from '../../../rdkit/molecule-structure/molecule-structure.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ChemdrawWrapperComponent } from "../../../chemdraw-wrapper/chemdraw-wrapper.component";
import { SelectItem } from 'primeng/api';
import { InputWLimitedDecComponent } from "../../../utility/input-w-limited-dec/input-w-limited-dec.component";
import { IFilterAngularComp } from 'ag-grid-angular';
import { IFilterParams, AgPromise, IDoesFilterPassParams, IAfterGuiAttachedParams } from 'ag-grid-enterprise';
import { LibraryModuleComponent, StructureSearchType } from '../library-module/library-module.component';
import _ from 'lodash';

@Component({
  selector: 'app-library-module-filter',
  standalone: true,
  imports: [
    PanelModule,
    InputGroupModule,
    ButtonModule,
    MoleculeStructureComponent,
    CommonModule,
    FormsModule,
    DialogModule,
    ChemdrawWrapperComponent,
    DropdownModule,
    InputNumberModule,
    FloatLabelModule,
    InputWLimitedDecComponent
],
  templateUrl: './library-module-filter.component.html',
  styleUrl: './library-module-filter.component.scss'
})

export class LibraryModuleFilterComponent implements IFilterAngularComp {

  @ViewChild("cdw") cdw: ChemdrawWrapperComponent;

  structureSearchType = StructureSearchType;
  showChemDraw: boolean = false;
  searchTypesOptions: SelectItem<number>[] = [];
  drawnStructure: string;
  lmComponent: LibraryModuleComponent;
  uiFilterData: LibraryModuleFilterComponentData = {
    searchSmiles: undefined,
    selectedSearchType: 0,
    similarityThreshold: 0
  };
  appliedFilterData: LibraryModuleFilterComponentData = null;

  constructor(
  ) {

    Object.entries(StructureSearchType).forEach(([key, val]) => {
      this.searchTypesOptions.push({label: key, value: val} as SelectItem<number>);
    });

  }

  filterModified() {
    if (this.appliedFilterData || this.uiFilterData.searchSmiles) {
      this.lmComponent.filterModified("structure", this.appliedFilterData, this.uiFilterData);
    }
  }

  applyFilter() {

    let appliedDataString = this.appliedFilterData ? JSON.stringify(this.appliedFilterData) : null;
    let uiDataString = this.uiFilterData ? JSON.stringify(this.uiFilterData) : null;

    if ((!this.appliedFilterData && this.uiFilterData.searchSmiles) ||
        (appliedDataString && uiDataString && appliedDataString != uiDataString)) {
      this.lmComponent.structureFilterData = _.cloneDeep(this.uiFilterData);
      this.appliedFilterData = _.cloneDeep(this.uiFilterData);
      this.lmComponent.filterChanged("structure");
    }
  }

  clearSmilesClicked() {
    if (this.uiFilterData.searchSmiles) {
      this.uiFilterData.searchSmiles = undefined;
      this.filterModified();
    }
  }

  resetUiValues() {
    this.uiFilterData = _.cloneDeep(this.lmComponent.defaultFilterData);
  }

  reset() {
    this.resetUiValues();
    this.filterModified();
  }

  showChemDrawDialog() {
    this.drawnStructure = this.uiFilterData.searchSmiles;
    this.showChemDraw = true;
  }

  applyDrawnStructure() {
    this.showChemDraw = false;
    this.cdw.chemdraw.getSMILES((smiles: any, error: any) => {
      this.uiFilterData.searchSmiles = smiles;
    });
    this.filterModified();
  }

  agInit(params: IFilterParams<any, any>): void {
    this.lmComponent = params.context as LibraryModuleComponent;
    this.resetUiValues();
  }

  isFilterActive(): boolean {
    // returning true from this method kills performance in ag-grid for some reason
    return false;
  }

  getModel() {
    return this.appliedFilterData;
  }

  setModel(model: any): void | AgPromise<void> {
    this.appliedFilterData = model as LibraryModuleFilterComponentData;
    this.uiFilterData = _.cloneDeep(this.appliedFilterData);
  }

  refresh?(newParams: IFilterParams): boolean {
    return false;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return true;
  }

  onNewRowsLoaded?(): void {

  }

  onAnyFilterChanged?(): void {

  }

  getModelAsString?(model: any): string {
    return JSON.stringify(this.appliedFilterData);
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {

  }

  afterGuiDetached?(): void {

  }

}

export interface LibraryModuleFilterComponentData {
  searchSmiles: string;
  selectedSearchType: number;
  similarityThreshold: number;
}
